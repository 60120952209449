/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 61.2.8-v202406060200
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface NotificationConfigurationOutputV1 {
    /**
     * Email addresses or User IDs that will be in the Bcc list of the email
     */
    "bccEmailRecipients": Array<models.EmailNotificationRecipientOutputV1>;
    /**
     * The format in which capsules are grouped in the notification
     */
    "capsuleGrouping"?: CapsuleGroupingEnum;
    /**
     * The list of properties included in the notification
     */
    "capsuleProperties": Array<string>;
    /**
     * Email addresses or User IDs that will be in the Cc list of the email
     */
    "ccEmailRecipients": Array<models.EmailNotificationRecipientOutputV1>;
    /**
     * Text that provides additional context in the notification
     */
    "contextualText"?: string;
    /**
     * The format of the report in the notification
     */
    "reportFormat"?: ReportFormatEnum;
    /**
     * IANA name of the time zone for notification content. If empty it means UTC is being used
     */
    "timezone"?: string;
    /**
     * Email addresses or User IDs that will be in the To list of the email
     */
    "toEmailRecipients": Array<models.EmailNotificationRecipientOutputV1>;
}

export enum CapsuleGroupingEnum {
    CONDITION = 'CONDITION' as any,
    CAPSULE = 'CAPSULE' as any,
    ALL = 'ALL' as any
}
export enum ReportFormatEnum {
    TEXT = 'TEXT' as any,
    PDF = 'PDF' as any
}
